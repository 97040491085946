html, body, #root{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

*{
    list-style: none;
}

.min-h-100{
    min-height: 100%;
}

.white-space-wrap{
    white-space: pre;
}

.i-town-background-image{
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    background-size: cover;
    &::before{
        content: '';
        background-color: rgba(34, 25, 34, 0.583);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
    }
}

.list-item-body{
    line-height: 2rem;
}

.letter-spacing-0{
    letter-spacing: 0rem;
}


.letter-spacing-1{
    letter-spacing: 0.1rem;
}

.letter-spacing-2{
    letter-spacing: 0.2rem;
}

.letter-spacing-3{
    letter-spacing: 0.3rem;
}

.letter-spacing-4{
    letter-spacing: 0.4rem;
}

.text-black-i{
    color:#331D30
}

.border-head{
    border-top: 1px solid;
}

.border-orange{
    border-color: orange;
}

.plan-info {
    color: rgb(44, 88, 154);
    background-color: #B0D0FF;
    border-color: #96C0FF;
}

.bg-content{
    background-color: #E2E5E8;
}

.bg-base{
    background-color: #E6E6E6;
}